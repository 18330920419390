import React, { useEffect, useState } from 'react';

import { Divider, Drawer, Grid, Input, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';

import { setDocument, setSelectedTemplateId, useDocument, useSamplesDrawerOpen } from '../../documents/editor/EditorContext';


import { AddCircleRounded } from '@mui/icons-material';
import Reader  from '../../../../email-builder/src/Reader/core';
import { environment } from '../../environment';

export const SAMPLES_DRAWER_WIDTH = 300;

export default function SamplesDrawer() {
  const samplesDrawerOpen = useSamplesDrawerOpen();
  const document = useDocument();
  const [templates, setTemplates] = useState<[]>([]);
  const tpls = templates as any[];
  const newObject = {
    "root": {
        "type": "EmailLayout",
        "data": {
            "backdropColor": "#F5F5F5",
            "canvasColor": "#FFFFFF",
            "textColor": "#262626",
            "fontFamily": "MODERN_SANS",
            "childrenIds": []
        }
    }
};

  const fetchTemplates = async () => {
    const endpoint = `${environment.API_ROUTE}/api/email?page=1`;
    const response = await fetch(endpoint);
    const json = await response.json();     
    setTemplates(json.templates);
  }
  function generateUUIDv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  
  useEffect(() => {
    console.log(document);
    fetchTemplates();
  }, []);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={samplesDrawerOpen}
      sx={{
        width: samplesDrawerOpen ? SAMPLES_DRAWER_WIDTH : 0,
      }}
    >
      <Stack spacing={3} py={1} px={2} width={SAMPLES_DRAWER_WIDTH} justifyContent="space-between" height="100%">
        <Stack spacing={2} sx={{ '& .MuiButtonBase-root': { width: '100%', justifyContent: 'flex-start' } }}>
          <Typography variant="h6" component="h1" sx={{ p: 0.75 }}>
            Buzzfy | Email Builder
          </Typography>

          {/* <Stack alignItems="flex-start">
              <img style={{width: "150px", height: "200px", objectFit: "cover"}} src='https://s3-alpha.figma.com/hub/file/4927480915/3835a38d-4275-43de-9694-577ff819411c-cover.png'></img>
              <img style={{width: "150px", height: "200px", objectFit: "cover"}} src='https://s3-alpha.figma.com/hub/file/4927480915/3835a38d-4275-43de-9694-577ff819411c-cover.png'></img>                       
          </Stack> */}
          <Input placeholder="Search"></Input>
          <ToggleButtonGroup exclusive size="small">
              <ToggleButton value="desktop">
                <Tooltip title="Desktop view">                  
                  <span>Explore</span>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="desktop">
                <Tooltip title="Desktop view">
                <span>My templates</span>
                </Tooltip>
              </ToggleButton>
          </ToggleButtonGroup>
          <Grid container rowSpacing={1} colSpacing={1}>
            {(tpls as any[]).map((e: any) => {
             return <Grid style={{cursor:"pointer", height: "225px", margin: "2px", width: "125px", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={(a) => {
                setDocument(e.document);
                setSelectedTemplateId(e.id);
             }}>
                  <div style={{zoom: "0.25", width: "100%", height: "100%", pointerEvents: "none" }}>
                    <Reader document={e.document} rootBlockId="root"></Reader>
                    </div>
              </Grid>
              })}
              <Grid onClick={() => {
                setDocument(newObject);
                setSelectedTemplateId(generateUUIDv4());
              }} style={{cursor:"pointer", height: "225px", margin: "2px", width: "125px", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <AddCircleRounded></AddCircleRounded>
              </Grid>
          </Grid>
          <Divider />

          <Stack>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
}
