import React, { createContext, useContext, useEffect, useState } from 'react';
import { z } from 'zod';

import { Avatar, AvatarPropsSchema } from '@usewaypoint/block-avatar';
import { Button, ButtonPropsSchema } from '../../../block-button/src';
import { Divider, DividerPropsSchema } from '@usewaypoint/block-divider';
import { Heading, HeadingPropsSchema } from '@usewaypoint/block-heading';
import { Html, HtmlPropsSchema } from '@usewaypoint/block-html';
import { Image, ImagePropsSchema } from '@usewaypoint/block-image';
import { Spacer, SpacerPropsSchema } from '@usewaypoint/block-spacer';
import { Text, TextPropsSchema } from '@usewaypoint/block-text';



import ColumnsContainerPropsSchema from '../blocks/ColumnsContainer/ColumnsContainerPropsSchema';
import ColumnsContainerReader from '../blocks/ColumnsContainer/ColumnsContainerReader';
import { ContainerPropsSchema } from '../blocks/Container/ContainerPropsSchema';
import ContainerReader from '../blocks/Container/ContainerReader';
import { EmailLayoutPropsSchema } from '../blocks/EmailLayout/EmailLayoutPropsSchema';
import EmailLayoutReader from '../blocks/EmailLayout/EmailLayoutReader';
import { Products, ProductsPropsSchema } from '../../../block-products/src';
import { buildBlockConfigurationDictionary, buildBlockConfigurationSchema, buildBlockComponent } from '../../../document-core/src';
const ReaderContext = createContext<TReaderDocument>({});

function useReaderDocument() {
  return useContext(ReaderContext);
}

var GlobalDocument = {};

const READER_DICTIONARY = buildBlockConfigurationDictionary({
  ColumnsContainer: {
    schema: ColumnsContainerPropsSchema,
    Component: ColumnsContainerReader,
  },
  Container: {
    schema: ContainerPropsSchema,
    Component: ContainerReader,
  },
  EmailLayout: {
    schema: EmailLayoutPropsSchema,
    Component: EmailLayoutReader,
  },  
  Avatar: {
    schema: AvatarPropsSchema,
    Component: Avatar,
  },
  Products: {
    schema: ProductsPropsSchema, 
    Component: Products,
  },
  Button: {
    schema: ButtonPropsSchema,
    Component: Button,
  },
  Divider: {
    schema: DividerPropsSchema,
    Component: Divider,
  },
  Heading: {
    schema: HeadingPropsSchema,
    Component: Heading,
  },
  Html: {
    schema: HtmlPropsSchema,
    Component: Html,
  },
  Image: {
    schema: ImagePropsSchema,
    Component: Image,
  },
  Spacer: {
    schema: SpacerPropsSchema,
    Component: Spacer,
  },
  Text: {
    schema: TextPropsSchema,
    Component: Text,
  },
});

export const ReaderBlockSchema = buildBlockConfigurationSchema(READER_DICTIONARY);
export type TReaderBlock = z.infer<typeof ReaderBlockSchema>;

export const ReaderDocumentSchema = z.record(z.string(), ReaderBlockSchema);
export type TReaderDocument = Record<string, TReaderBlock>;

const BaseReaderBlock = buildBlockComponent(READER_DICTIONARY);

export type TReaderBlockProps = { id: string; };
export type TReaderBlockPropsWithDocument = { id: string; document: TReaderDocument; };
export function ReaderBlock({ id }: TReaderBlockProps) {  
  var document = useReaderDocument();   
  if(!document[id]){
    document = GlobalDocument;
  }    
  //But here, when we try to receive ( after the document arrives ) the document from useReaderDocument (Context) is empty
  return <BaseReaderBlock {...document[id]} />;
}


export type TReaderProps = {
  document: Record<string, z.infer<typeof ReaderBlockSchema>>;
  rootBlockId: string;  
};
export default function Reader({ document, rootBlockId }: TReaderProps) {
  console.log("Here");
  const [contextDocument, setContextDocument] = useState<TReaderDocument>(document);

  // Ensure that the context is updated whenever the document changes
  useEffect(() => {        
    setContextDocument(document);
  }, [document]);
  GlobalDocument = document;
  return (
    <ReaderContext.Provider value={contextDocument}>
      <ReaderBlock id={rootBlockId} />
    </ReaderContext.Provider>
  );
}
